import type {
  MindboxCustomer,
  MindboxOperation,
  MindboxProductKey,
  MindboxRequestData,
} from '../types';

export function getOperationData(
  operation: MindboxOperation,
  customer?: MindboxCustomer,
  page?: string
): MindboxRequestData {
  // В качестве id статей, курсов и страниц в mindbox используются slug-и
  const webSite = page || window.location.pathname;
  const productKey: MindboxProductKey =
    operation === 'AddArticleToList' ? 'addProductToList' : 'viewProduct';

  const operationData: MindboxRequestData = {
    operation,
    data: {
      [productKey]: {
        product: {
          ids: {
            webSite,
          },
        },
      },
    },
  };

  if (customer) {
    operationData.data.customer = customer;
  }

  return operationData;
}
